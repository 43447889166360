
import Link from "next/link";
import React from "react";
import { useRecoilValue } from "recoil";
import { configStoreData } from "../../selectors/store";
import FooterEccComics from "./FooterEccComics";
import FooterEccEdiciones from "./FooterEccEdiciones"

const HomeFooter = () => {
  const storeId = useRecoilValue(configStoreData);

  return (

    <>
      {storeId == 1 &&
        <FooterEccEdiciones />
      }

      {storeId == 9 &&
        <FooterEccComics />
      }
    </>

  );

};

export default React.memo(HomeFooter);
