
import Link from "next/link";
import React from "react";
import styles from './index.module.css';

const FooterEccComics = () => (
  <div className="container-fluid">
    <div className={styles.eccComicsFooter}>
      <div className={`navbar-nav line6 ${styles.lineBorder}`}>
        <div className={styles.upperMenu}>
          <ul>
            <li className="nav-item"><Link href="#">CONÓCENOS</Link></li>
            <li className="nav-item"><Link href="#"> NUESTRAS TIENDAS </Link></li>
            <li className="nav-item"><Link href="#"> AYUDA  </Link></li>
            <li className="nav-item"><Link href="#"> CONTACTO </Link></li>
          </ul>
        </div>
        <ul>
          <li className="nav-item"><Link href="/ayuda/aviso_legal">AVISO LEGAL</Link></li>
          <li className="nav-item "><Link href="/ayuda/politica_privacidad">POLÍTICA DE PRIVACIDAD</Link></li>
          <li className="nav-item"><Link href="/ayuda/politica_cookies">POLÍTICA DE COOKIES</Link></li>
          <li className="nav-item"><Link href="/ayuda/condiciones_contratacion">CONDICIONES DE CONTRATACIÓN</Link></li>
          <li className="nav-item"><a rel="noreferrer" target="_blank" href="https://content.eccediciones.com/documentos/desentimiento.pdf" alt="">CONTRATO DE DESENTIMIENTO</a></li>
          <li className="nav-item"><a rel="noreferrer" target="_blank" href="https://compliance.legalsending.com/canal/?C=48602549019023114" alt="">CANAL ÉTICO</a></li>
        </ul>
        <p>Copyright © 2022 El Catálogo del Cómic S.L. Todos los derechos reservados. </p>
      </div>
    </div>
  </div>

);

export default React.memo(FooterEccComics);
